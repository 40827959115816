/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
import { DynamicDestinations } from './destinations-dynamicitems';

(function () {
  'use strict';

  angular.module('comcast.settings').controller('SettingsDestinationsController', ['$mdDialog', '$q', '$scope', '$state', '$stateParams', '$timeout', '$window', 'addelivery.event.constants', 'loginService', 'DestinationResourceFactory', 'NotificationService', function ($mdDialog, $q, $scope, $state, $stateParams, $timeout, $window, EventConstants, loginService, DestinationResourceFactory, NotificationService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.dynamicItems = new DynamicDestinations($stateParams, $timeout, DestinationResourceFactory);
    vm.sortObj = {
      field: $stateParams.sortField ? $stateParams.sortField : 'alias',
      direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC'
    };
    vm.selectedDestinations = [];
    vm.lastSelection = -1;
    vm.searching = true;
    vm.onlyArchivesSelected = true;
    vm.viewSelections = {
      deliveryConfirmationUrl: false,
      costEstimateReportUrl: false,
      receiptConfirmationReportUrl: false
    };
    vm.viewUrls = {
      deliveryConfirmationUrl: false,
      costEstimateReportUrl: false,
      receiptConfirmationReportUrl: false
    };
    vm.deliveryPriorities = [];
    vm.onlyDraftsSelected = true;
    vm.destinationStatuses = {};
    vm.destinationCapabilities = {};
    vm.destinationDeleteCount = 0;
    vm.destinationNotDeleteCount = 0;
    vm.bowser = bowser;
    /* BINDABLE : METHODS*/

    vm.sortDestinations = sortDestinations;
    vm.selectDestination = selectDestination;
    vm.isSelected = isSelected;
    vm.openDetail = openDetail;
    vm.newDestination = newDestination;
    vm.archiveDestinations = archiveDestinations;
    vm.deleteDestinations = deleteDestinations;
    vm.hasPermission = loginService.hasPermission;
    vm.exportDestinations = exportDestinations;
    /* EVENTS */
    //Register any event listeners

    $scope.$on('$destroy', function () {
      $('#settings-footer').removeClass('hidden');
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function sortDestinations(field) {
      if (vm.sortObj.field === field) {
        vm.sortObj.direction = vm.sortObj.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        vm.sortObj.field = field;
        vm.sortObj.direction = 'ASC';
      }

      $state.go('settingDestinations', {
        sortField: vm.sortObj.field,
        sortDirection: vm.sortObj.direction
      }, {
        inherit: true
      });
    }

    function selectDestination(destination, index, event) {
      if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
        vm.selectedDestinations = [];
      }

      if (event.shiftKey && vm.lastSelection > -1) {
        // Do multiselect
        var toIndex = Math.max(index, vm.lastSelection);
        var fromIndex = Math.min(index, vm.lastSelection);

        for (var i = fromIndex; i <= toIndex; i++) {
          if (vm.selectedDestinations.indexOf(i) < 0) {
            vm.selectedDestinations.push(i);
          }
        }
      } else if (vm.selectedDestinations.indexOf(index) < 0) {
        vm.selectedDestinations.push(index);
      } else {
        vm.selectedDestinations.splice(vm.selectedDestinations.indexOf(index), 1);
      }

      vm.onlyArchivesSelected = true;
      vm.onlyDraftsSelected = true;

      for (var destIndex = 0; destIndex < vm.selectedDestinations.length; destIndex++) {
        if (vm.dynamicItems.getItemAtIndex(vm.selectedDestinations[destIndex]).archivedFlag !== true) {
          vm.onlyArchivesSelected = false;
        }

        if (vm.dynamicItems.getItemAtIndex(vm.selectedDestinations[destIndex]).deliveryStatus !== 'DRAFT') {
          vm.onlyDraftsSelected = false;
        }
      }

      vm.lastSelection = index;
    }

    function isSelected(rolesToValidate) {
      return vm.selectedDestinations.length < 1 || !loginService.hasPermission(rolesToValidate.split(','));
    }

    function openDetail(destinationId) {
      angular.element(document.querySelector('.sidebar')).addClass('invisible');
      $state.go('destinationDetails', {
        id: destinationId
      }).then(function () {
        // Apply the animation ONLY when this transition happens
        $timeout(function () {
          angular.element(document.querySelector('.sidebar')).removeClass('invisible');
          angular.element(document.querySelector('.destination-detail-list-area .destination-grid')).addClass('slide-left-transition');
        }, 0);
      });
    }

    function newDestination() {
      $state.go('destinationDetails', {
        id: 'new'
      });
    }

    function archiveDestinations($event) {
      var toArchive = false;

      for (var i = 0; i < vm.selectedDestinations.length; i++) {
        if (vm.dynamicItems.getItemAtIndex(vm.selectedDestinations[i]).archivedFlag === false) {
          toArchive = true;
        }
      }

      _archiveDestinations(vm.selectedDestinations, toArchive, $event);
    }

    function deleteDestinations() {
      var confirmOrNot;
      var confirm = $mdDialog.confirm().title('Delete Destination').textContent('Are you sure you want to delete destination(s)?').ariaLabel('Are you sure you want to delete destination(s)?').ok('DELETE').cancel('Cancel');
      confirmOrNot = $mdDialog.show(confirm);
      confirmOrNot.then(function () {
        // User confirmed the remove destination function
        $q.all(vm.selectedDestinations.map(function (cur, index, arr) {
          return _deleteDestination(vm.dynamicItems.getItemAtIndex(cur));
        })).then(function success(results) {
          var msg = vm.destinationDeleteCount > 0 ? vm.destinationDeleteCount + ' destination(s) were delete.  ' : '';
          msg += vm.destinationNotDeleteCount > 0 ? vm.destinationNotDeleteCount + ' destination(s) were UNABLE to be deleted.' : '';

          if (msg) {
            NotificationService.showNotificationToast(msg);
          }

          if (vm.destinationDeleteCount > 0) {
            $state.reload();
          }

          vm.destinationDeleteCount = 0;
          vm.destinationNotDeleteCount = 0;
        });
      }, function () {// User cancelled the remove destination function
      });
    }

    function exportDestinations() {
      // Download the export
      $window.open(vm.dynamicItems.exportUrl + '&authorization=' + loginService.getJwt());
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    var _archiveDestinations = function _archiveDestinations(destinations, flag, ev) {
      var filteredDestinations = destinations.filter(function (cur, index, arr) {
        return vm.dynamicItems.getItemAtIndex(cur).archivedFlag !== flag;
      });
      var confirmOrNot;

      if (flag) {
        var confirm = $mdDialog.confirm().title('Would you like to continue?').textContent("Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'.").ariaLabel("Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'.").targetEvent(ev).ok('Continue').cancel('Cancel');
        confirmOrNot = $mdDialog.show(confirm);
      } else {
        confirmOrNot = $q.when(true);
      }

      confirmOrNot.then(function () {
        // User confirmed the archive function
        // Set the flag to show whether or not there are any archived orders selected (since we're making them all the same with this function)
        vm.onlyArchivesSelected = flag;
        filteredDestinations = filteredDestinations.map(function (cur, index, arr) {
          var destination = vm.dynamicItems.getItemAtIndex(cur);
          destination.archivedFlag = flag;
          return destination;
        });
        $q.all(filteredDestinations.map(function (cur, index, arr) {
          var currItem = angular.copy(cur);
          delete currItem.DestinationType;
          return DestinationResourceFactory.save({
            id: currItem.id
          }, currItem);
        })).then(function success() {// Destination successfully archived
        }, function failure() {// Destination Failed to be Archived
        });
      }, function () {// User cancelled the archive function
      });
    };

    function _deleteDestination(cur) {
      var deletePromise = $q.defer();
      DestinationResourceFactory["delete"]({
        id: cur.id
      }, {}, function (success) {
        //individual delete succeeded
        vm.destinationDeleteCount++;
        deletePromise.resolve('true');
      }, function (failure) {
        //individual delete failure
        vm.destinationNotDeleteCount++;
        deletePromise.resolve('true');
      });
      return deletePromise.promise;
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      $('.sidebar').removeClass('hidden');
      $('#settings-footer').addClass('hidden');
    }
  }]);
})();